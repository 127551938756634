.input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 8rem;

  & > span {
    text-transform: capitalize;
    font-size: 1.25rem;
  }
  
  & > input {
    outline: 0;
    border-radius: 0.3rem;
    border: 1px solid #ced4da;
    padding: 0.5rem;
    height: 3rem;
  }

  & > p {
    color: red;
    font-size: 0.75rem;
  }
}
.required::before {
  content: "* ";
  color: red;
}

